import { Avatar, Box, HStack, IconButton, Link, Text, VStack } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { BsGithub, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { ROUTE_PATHS } from '../../App';
import { FULL_HEIGHT } from '../../global-constants/sizes';
import { BLOG_CARD_WIDTH } from '../common/BlogCard';
import ReactGA from 'react-ga4';

const textStyle = {
  fontSize: '18px',
  fontFamily: 'Roboto',
  color: 'gray',
};
const AboutContainer = () => {
  const onClick = (link: string, category: string) => {
    ReactGA.event({
      category: category,
      action: 'Click',
      label: 'platform',
    });

    window.open(link, '_blank');
  };
  return (
    <HStack w={BLOG_CARD_WIDTH * 2 + 30} minH={FULL_HEIGHT} margin={50} display="flex" alignItems="flex-start" justifyContent="flex-start">
      <VStack w="70%" display="flex" alignItems="flex-start" justifyContent="flex-start" paddingRight={10}>
        <HStack textAlign="start">
          <Text fontSize="22px" fontFamily="Roboto" fontWeight="bold" color="#4d4d4d">
            Hello there!
          </Text>
          <Player style={{ width: 40, height: 40 }} loop autoplay src={require('../../assets/animations/hi.json')} />
        </HStack>
        <Text style={{ ...textStyle }}>
          Thank you for stopping by! My name is Xegai Hadgu, a Fullstack Developer with a degree in Computer Science and Mathematics. I've
          been programming for the past {new Date().getFullYear() - 2019} years now.
        </Text>
        <Text style={{ ...textStyle }}>
          In my free time, I enjoy working on personal projects, exploring new technologies, and contributing to open-source initiatives.
          Throughout my career, I've developed a variety of projects, including fully responsive websites, and native and hybrid mobile
          apps. Curious about my work? You can explore my projects{' '}
          <Link href={`./${ROUTE_PATHS.SIDE_PROJECTS}`} target="_blank" color="#378CE7">
            here{' '}
          </Link>
          .
        </Text>
        <Text style={{ ...textStyle }}>
          When I'm not coding (which is rare!), you'll likely find me playing soccer, hanging out with friends, or hiking. It's a pretty
          boring life, isn't it? :)
        </Text>

        <Text style={{ ...textStyle }}>
          I created this blog to document and share the technologies I learn, the projects I’m working on, and anything else I think is
          worth sharing. I hope you find something here that's useful!
        </Text>
      </VStack>
      <Box w="30%">
        <VStack
          w={350}
          height={350}
          borderRadius={20}
          padding={10}
          backgroundColor="#333333"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Avatar size="2xl" name="Xegai Hadgu" src={require('../../assets/profile.JPG')} />
          <Text fontSize="20px" fontFamily="Roboto" textAlign="center" fontWeight="bold" color="#cccccc">
            Xegai Hadgu
          </Text>
          <Text fontSize="18px" fontFamily="Roboto" textAlign="center" fontWeight="500" color="#378CE7">
            FullStack Developer
          </Text>
          <HStack marginTop={5}>
            <IconButton
              colorScheme="undefined"
              aria-label="Github"
              size="lg"
              icon={
                <BsGithub
                  style={{
                    color: 'gray',
                    fontSize: 25,
                  }}
                />
              }
              onClick={() => onClick('https://github.com/xegai-hadgu', 'Github')}
            />
            <IconButton
              colorScheme="undefined"
              aria-label="Linkedin"
              size="lg"
              icon={
                <BsLinkedin
                  style={{
                    color: 'gray',
                    fontSize: 25,
                  }}
                />
              }
              onClick={() => onClick('https://www.linkedin.com/in/xegai-hadgu/', 'LinkedIn')}
            />
            <IconButton
              colorScheme="undefined"
              aria-label="Twitter"
              size="lg"
              icon={
                <BsTwitter
                  style={{
                    color: 'gray',
                    fontSize: 25,
                  }}
                />
              }
              onClick={() => {}}
            />
          </HStack>
        </VStack>
      </Box>
    </HStack>
  );
};

export default AboutContainer;
