export enum LANG_TYPE {
  REACT = 'React',
  TYPESCRIPT = 'Typescript',
  JAVASCRIPT = 'Javascript',
  JAVA = 'Java',
  CPP = 'C++',
  Python = 'Python',
  SPRINT_BOOT = 'Spring Boot',
}

export enum PROJECT_TYPE {
  WEBSITE = 'website',
  MOBILE = 'mobile',
}

export interface PROJECT {
  id: number;
  title: string;
  type: PROJECT_TYPE;
  imagePath: string;
  description: string[];
  techStacks: string[]; //technologies used
  githubLink: string;
  websiteLink: string | undefined;
  appStoreLink: string | undefined;
}

export const PROJECTS: PROJECT[] = [
  {
    id: 4,
    title: 'TSFNA',
    type: PROJECT_TYPE.WEBSITE,
    imagePath: require('../assets/projects/tsfna.png'),
    description: [
      'The official website of the Tigrayan Soccer Federation in North America (TSFNA), which I developed and continue to maintain.',
    ],
    techStacks: ['React', 'AWS', 'MySQL', 'Typescript', 'Material UI'],
    githubLink: 'https://github.com/xegai-hadgu/tsfna-official',
    websiteLink: 'https://tsfna.org/',
    appStoreLink: undefined,
  },
  {
    id: 8,
    title: 'Geez',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/geez.png'),
    description: [
      "An Ethiopian (Ge'ez) and Gregorian calendar app with over 500 downloads and a 5-star rating. The app features a date converter, a comprehensive list of religious and national Ethiopian holidays and events, and detailed background information about each occasion.",
    ],
    techStacks: ['React Native', 'Typescript', 'SQLite'],
    githubLink: 'https://github.com/xegai-hadgu/geez',
    websiteLink: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/geez/id6736929275',
  },
  {
    id: 9,
    title: 'Reminderly',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/reminderly.png'),
    description: [
      "An event reminder app designed to help you keep track of important dates and occasions, such as birthdays, anniversaries, appointments, and more. With customizable notifications, you can set when(date and time) you'd like to be reminded about upcoming events.",
    ],
    techStacks: ['React Native', 'Typescript', 'SQLite'],
    githubLink: 'https://github.com/xegai-hadgu/reminderly',
    websiteLink: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/reminderly/id6738805476',
  },
  {
    id: 1,
    title: 'US Citizenship',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/citizen.png'),
    description: [
      'A React Native mobile app with over 200 active users, designed to help users prepare for the US citizenship test and learn about US history.',
      'The app offers various learning formats, including multiple-choice quizzes, flashcards, writing exercises, and reading activities.',
    ],
    techStacks: ['React Native', 'Typescript', 'Google Civic Information API', 'Firebase'],
    githubLink: 'https://github.com/xegai-hadgu/USCitizenshipTest',
    websiteLink: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/us-citizenship/id1602939403',
  },
  {
    id: 2,
    title: 'Gezana',
    type: PROJECT_TYPE.WEBSITE,
    imagePath: require('../assets/projects/gezana.png'),
    description: [
      'Gezana which means "our house" in Tigrigna is, a Tigrayan(Ethiopian) community-based platform to that lets you find nearby Tigrayan-owned services, such as restaurants, hotels, religious centers etc',
      'The platform currently has over 50 daily users and has over 50 registered services.',
    ],
    techStacks: ['React', 'Typescript', 'Chakra UI', 'AWS', 'MySQL'],
    githubLink: 'https://github.com/xegai-hadgu/Gezana-web',
    websiteLink: 'https://gezana.io/',
    appStoreLink: undefined,
  },
  {
    id: 3,
    title: 'Get Done',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/todo.png'),
    description: ['A simple and intuitive React Native mobile app.'],
    techStacks: ['React Native', 'Typescript', 'AsyncStorage'],
    githubLink: 'https://github.com/xegai-hadgu/GetDone',
    websiteLink: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/get-done/id1641388050',
  },
  {
    id: 5,
    title: 'Personal Blog',
    type: PROJECT_TYPE.WEBSITE,
    imagePath: require('../assets/projects/blog.png'),
    description: [
      'My personal blog, where I share insights, experiences, and lessons learned from my daily journey as a software engineer.',
    ],
    techStacks: ['React', 'Typescript', 'Chakra UI', 'Framer-motion'],
    githubLink: 'https://github.com/xegai-hadgu/codexpress',
    websiteLink: 'https://wedihadgu.com/',
    appStoreLink: undefined,
  },
  {
    id: 6,
    title: 'Safe Locker',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/safe-locker.png'),
    description: [
      'A React Native mobile app designed to securely store your photos and passwords. It gives you complete control over your files and sensitive information, ensuring your data remains private and protected.',
      'The app has over 30 active users and has a 4.5-star rating on the App Store.',
    ],
    techStacks: ['React Native', 'Typescript', 'SQLite'],
    githubLink: 'https://github.com/xegai-hadgu/safeLocker-2',
    websiteLink: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/safe-locker/id1609526884',
  },
  {
    id: 7,
    title: 'Smart Calculator',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/calc.png'),
    description: [
      'An all-in-one smart calculator app that combines functionality and convenience. It includes a basic and advanced calculator, geometry tools, and other useful features.',
    ],
    techStacks: ['React Native', 'Typescript', 'SQLite'],
    githubLink: 'https://github.com/xegai-hadgu/SmartCalculator',
    websiteLink: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/smart-calc/id1608259008',
  },
];
